<template lang="">
    <div v-if="items.length > 0">
        <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/importacao-cadastro-estrutura">
            <template #content>
                <div class="card p-fluid w-full">
                    <Steps :model="items" :readonly="true">
                        <template #item="{ item }">
                            <router-link :to="item?.to" custom v-slot="{ href, navigate }">
                                <a class="p-menuitem-link router-link-active router-link-active-exact" :href="href" @click="navigate">
                                    <span class="p-steps">
                                        <img
                                            :src="
                                                item?.index === formObject?.step
                                                    ? 'layout/images/pages/icon-editando.svg'
                                                    : item?.index < formObject?.step
                                                    ? 'layout/images/pages/icon-editado.svg'
                                                    : 'layout/images/pages/icon-falta-editar.svg'
                                            "
                                            width="28"
                                            height="28"
                                        />
                                    </span>
                                    <span class="p-steps-title">{{ item.label }}</span>
                                </a>
                            </router-link>
                        </template>
                    </Steps>
                    <router-view
                        v-slot="{ Component }"
                        :formData="formObject"
                        @prevPage="prevPage($event)"
                        @nextPage="nextPage($event)"
                        @setFormData="setFormData($event)"
                    >
                        <keep-alive>
                            <component :is="Component" />
                        </keep-alive>
                    </router-view>
                </div>
            </template>
        </AppPanel>
    </div>
</template>

<script>
import { getClientBase } from '@/services/http';
import EnumCadastroImportacaoExcel from '../../enums/EnumCadastroImportacaoExcel';

export default {
    data() {
        return {
            id: null,
            formObject: { step: 1 },
            items: []
        };
    },
    async created() {
        if (this.$route.params.id !== 'new') {
            this.id = this.$route.params.id;

            const response = await getClientBase().get(`/importacao-estrutura/${this.id}`);
            const cadastros = response.data.cadastros.map((cad) => {
                if (cad === 'CL') {
                    return { id: cad, descricao: 'Cliente' };
                }
                if (cad === 'FU') {
                    return { id: cad, descricao: 'Funcionário' };
                }
                if (cad === 'UN') {
                    return { id: cad, descricao: 'Unidade' };
                }
                if (cad === 'SE') {
                    return { id: cad, descricao: 'Setor' };
                }
                if (cad === 'CA') {
                    return { id: cad, descricao: 'Cargo' };
                }
            });

            let cadastrosLista = [];
            if (response.data.tipoImportacao === 'NC') {
                cadastrosLista = [
                    {
                        id: 'CL',
                        descricao: 'Cliente'
                    },
                    ...EnumCadastroImportacaoExcel
                ];
            } else {
                cadastrosLista = EnumCadastroImportacaoExcel;
            }

            this.formObject = {
                step: 3,
                clienteId: response.data.customerId,
                tipoImportacao: response.data.tipoImportacao,
                cadastros,
                cadastrosLista,
                link: [
                    `/importacao-cadastro-estrutura/${this.id}/tipo-importacao`,
                    `/importacao-cadastro-estrutura/${this.id}/selecao-cadastro`,
                    `/importacao-cadastro-estrutura/${this.id}/download-planilha`
                ]
            };

            this.items.push(
                {
                    index: 1,
                    label: 'Tipo de Importação',
                    to: `/importacao-cadastro-estrutura/${this.id}/tipo-importacao`
                },
                {
                    index: 2,
                    label: 'Seleção de Cadastro',
                    to: `/importacao-cadastro-estrutura/${this.id}/selecao-cadastro`
                },
                {
                    index: 3,
                    label: 'Download Planilha',
                    to: `/importacao-cadastro-estrutura/${this.id}/download-planilha`
                },
                {
                    index: 4,
                    label: 'Importação e Validação',
                    to: `/importacao-cadastro-estrutura/${this.id}/importacao-validacao`
                },
                {
                    index: 5,
                    label: 'Conclusão',
                    to: `/importacao-cadastro-estrutura/${this.id}/conclusao`
                }
            );
        } else {
            this.items.push(
                {
                    index: 1,
                    label: 'Tipo de Importação',
                    to: '/importacao-cadastro-estrutura/new/tipo-importacao'
                },
                {
                    index: 2,
                    label: 'Seleção de Cadastro',
                    to: '/importacao-cadastro-estrutura/new/selecao-cadastro'
                },
                {
                    index: 3,
                    label: 'Download Planilha',
                    to: '/importacao-cadastro-estrutura/new/download-planilha'
                },
                {
                    index: 4,
                    label: 'Importação e Validação',
                    to: '/importacao-cadastro-estrutura/new/importacao-validacao'
                },
                {
                    index: 5,
                    label: 'Conclusão',
                    to: '/importacao-cadastro-estrutura/new/conclusao'
                }
            );
        }
        this.$router.push(this.items[this.formObject.step - 1].to);
    },
    methods: {
        setFormData(value) {
            this.formObject.cadastros = value;
        },
        nextPage(event) {
            for (let field in event.formData) {
                this.formObject[field] = event.formData[field];
            }
            this.$router.push(this.items[event.pageIndex + 1].to);
        },
        prevPage(event) {
            for (let field in event.formData) {
                this.formObject[field] = event.formData[field];
            }
            this.$router.push(this.items[event.formData.pageIndex - 1].to);
        }
    }
};
</script>

<style scoped lang="scss">
::v-deep(b) {
    display: block;
}

::v-deep(.p-card-body) {
    padding: 2rem;
}
</style>
